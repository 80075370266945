.book{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	min-height: calc( 100vh - 6.4rem );

	&__bg{
		z-index: -100;
		position: absolute;
		top:0;
		left:0;
		width: 100vw;
		overflow: hidden;

		@include respond(phone){ height: calc(100vh - 6.4rem); }

		&>img{
			object-fit: contain;
			width: 100%;
			
			@include respond(phone){
				height: 100%;
				width: unset;
				object-fit: cover;
			}
		}
	}

	&__data{
		width: 100%;
		min-height: calc(100vh - 12rem);
		margin: 24rem 0;

		@include respond(phone){
			flex-direction: column;
			margin: 0;
		}

		&__container{
			flex: 1;

			&>div:nth-child(1){
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				
				@include respond(phone){
					flex-direction: column;
					padding: 0 ;
					margin-bottom: 2rem  ;
				}
			}

			&__cover{
				z-index: 20;
				flex: 0 0 48rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;
				
				@include respond(phone){
					width: 100%;
				}

				@include respond(tabPort){
					flex: 0 0 auto ;
				}
				
				&__image {
					width : 30rem;
					height: 48rem;
					object-fit: cover;
					z-index: 1;
					box-shadow: 0 0.2rem 1.6rem rgba($color-black, 0.2);

					@include respond(phone){
						width: 32rem;
						height: 51.2rem;
					}
				}
			}

			&__meta{
				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				@include respond(tabPort) {
					margin-left: 4rem ;
				}

				@include respond(phone) {
					margin-left: 0 ;
					margin-top: 4rem ;
				}

				&__cta{
					display: flex ;
				}

				&__cta > * + * {
					margin-left: 1rem ;
				}

				&__rating {
					display: flex ;
					align-items: center ;
					margin: 1rem 0;
					&__stars {
						display: flex ;
						align-items: center;
					}

					&__count {
						color: $color-neutral-500 ;
						font-weight: 500 ;
						margin-left: 2rem ;
					}
				}

				&__price {
					padding: 0.8rem 1.6rem ;
					width: min-content ;
					background-color: hsl(160,80%,40%) ;
					color: #fff ;
					display: flex ;
					align-items: center ;
					border-radius: 0.5rem ;
					font-weight: 700 ;
					font-size: 2rem ;
					margin-top: 1rem ;

					> svg {
						margin-right: 0.5rem ;
					}

					&--free {
						background-color: hsl(160,80%,40%) ;
					}
				}

			}

			&__desc{
				display: flex;
				background-color: $color-white;
				border-radius: 1.2rem;
				box-shadow: $shadow-md ;
				margin-top: -24vh;
				min-height: 50vh;
				position: relative;
				z-index: 0;

				@include respond(tabPort) {
					margin: 0 ;
					flex-direction: column ;
					padding: 0 ;
					box-shadow: none ;
					background-color: transparent ;
					margin-top: 2rem ;
				}

				@include respond(phone) {
					width: 100% ;
				}

				&__left {
					flex: 0 0 48rem ;
					padding: 4rem;
					margin-top: 22rem ;
					position: relative ;
					box-sizing: border-box ;

					@include respond(tabPort) {
						margin: 0 ;
						padding: 0 ;
					}
				}

				&__right {
					flex: 1 ;
					padding-right: 4rem ;
				}

				&__interacts{
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: flex-start ;
					padding: 2rem 0 ;
					
					&__item{
						color: $color-neutral-500 ;
						flex: 1 ;
						max-width: 16rem ;
						white-space: nowrap;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
					}
				}

				&__summary{
					&__contract {
						display: flex ;
						align-items: center ;
						padding: 2rem ;
						background-color: $color-neutral-200 ;
						border-radius: 1rem ;
						cursor: pointer ;
						max-width: 48rem ;

						&__label {
							font-weight: 500 ;
						}

						&__data {
							flex: 1 ;
						}

						&__icon {
							width : 3rem ;
							height: 3rem ;
							display: flex ;
							align-items: center ;
							justify-content: center ;
						}
					}

					&__head {
						text-transform: capitalize ;
						margin: 0 ;
						margin-top: 2rem ;
						font-weight: 500 ;
					}

					&__data {
						color: $color-neutral-500;
					}

					&__chips {
						display: flex ;
						flex-direction: row;
						position: relative ;
						margin-left: -0.75rem ;
						flex-wrap: wrap ;
						&__item {
							padding: 0.75rem 1.5rem ;
							background-color: $color-neutral-200 ;
							color: $color-neutral-700 ;
							margin: 0.5rem ;
							border-radius: 500px ;
						}
					}
				}

				&__tabs{
					flex: 1;
					width: 100%;

					&__container{
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
						margin-bottom: 2.4rem;
						border-bottom: 2px solid $color-neutral-200 ;

						&__item{
							display: flex ;
							align-items: center ;
							justify-content: center ;
							padding: 1.2rem 1.6rem;
							border-bottom: 0.2rem solid $color-white;
							transition: all 0.15s ease-in-out;
							color: $color-neutral-500 ;
							border-bottom: 4px solid transparent ; 

							&>svg {
								width : 3rem ;
								height: 3rem ;
								margin-right: 1rem ;
								stroke: currentColor ;
							}

							&>h5{
								text-transform: capitalize !important;
								font-weight: 600;
								color: currentColor ;
							}
							
							&--active{
								border-bottom: 4px solid $color-primary; 
								color: $color-primary ;
							}

							@include respond(phone) {
								flex: 1 ;
								padding: 1.2rem 0rem ;
							}

						}
					}

					&__data{
						&__action{
							border: 2px solid $color-neutral-200;
							width: 100% ;
							padding: 1rem ;
							border-radius: 1rem ;
							color: $color-neutral-500;
							display: flex ;
							align-items: center ;

							&__icon {
								width : 5rem ;
								height: 5rem ;
								flex: 0 0 5rem;
								display: flex ;
								align-items: center ;
								justify-content: center ;
							}

							&__label {
								flex: 1 ;
							}

						}

						&__review {
							&__rating{
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: flex-start;
								margin-bottom: 1.2rem;

								&__item{
									height: 3.2rem;
									width: 3.2rem;
									position: relative;

									&__trigger{
										height: 3.2rem;
										width: 1.6rem;
										object-fit: contain;
										position: absolute;
										top: 0;
										z-index: 10;

										&:first-of-type{ left: 0 }
										&:last-of-type{ right: 0 }
									}

									&__icon{
										height: 3.2rem;
										width: 3.2rem;
										object-fit: contain;
										position: absolute;
										top: 0;
										left: 0;
										z-index: 5;

										&--half{
											&:first-of-type{ left: 0 }
											&:last-of-type{ right: 0 }
										}
									}
								}
							}
						}

						&__reviews{
							width: 100%;
							min-height: 80rem;
							padding: 2rem ;
							padding-left: 0 ;

							&__item{

								&:not(:last-child){margin-bottom: 1.2rem;}

								&__header {
									display: flex ;
									justify-content: space-between ;
								}

								&__rating{
									display: flex;
									flex-direction: row;
									align-items: center;
									justify-content: flex-start;
									margin-bottom: 0.4rem;

									&__time{margin-left: 0.8rem;}

									&__item{
										height: 3rem;
										width: 3rem;
										position: relative;
	
										&__icon{
											height: 3rem;
											width: 3rem;
											object-fit: contain;
											position: absolute;
											top: 0;
											left: 0;
											z-index: 5;
	
											&--half{
												&:first-of-type{ left: 0 }
												&:last-of-type{ right: 0 }
											}
										}
									}
								}

								&__head{
									margin-bottom: 0 !important;
								}
							}
						}
					
						&__quotes {
							&__item {
								position: relative ;
								padding: 1.5rem ;
								&__icon {
									position: absolute ;
									top: 0 ;
									left: 0 ;
									transform: translate(-50%,-50%);
									color: $color-neutral-200 ;
								}

								&__time {
									position: absolute ;
									right: 0 ;
									top: 0 ;
								}
							}

							&__item + &__item {
								margin-top: 2rem ;
							}
						}
					}
				}

				
			}

			&__space{
				height: 40rem;
			}
		}
	}

	&__actions{
		position: fixed;
		bottom: 2.4rem;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 100;
		box-shadow: 0 0.3rem 3rem rgba($color-black, 0.2);
		background-color: $color-neutral-200;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		border-radius: 100rem;
		overflow: hidden;

		&__item{
			flex: 1;
			width: 12rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			transition: all 0.4s ease-in-out;
			padding: 1.6rem 2.4rem;
			color: $color-white;

			&:first-child{
				background-color: $color-green;

				&:hover{
					background-color: $color-green-dark;
				}
			}

			&:nth-child(2){
				background-color: $color-red;

				&:hover{
					background-color: $color-red-dark;
				}
			}
		}
	}
}