.accordian {
    width: 100% ;
    display: flex ;
    flex-direction: column ;

    &__header {
        display: flex ;
        align-items: center ;
        padding: 1.75rem ;
        width: 100% ;
        border-bottom: 2px solid $color-neutral-200 ;
        cursor: pointer ;
        color: $color-neutral-600 ;
        font-weight: 500 ;

        &__custom {
            flex: 1 ;
        }

        &__toggle {
            width: 3rem ;
            height: 3rem ;
            display: flex ;
            align-items: center ;
            justify-content: center;
            overflow: hidden ;
            transition: transform 0.1s ease-out ;
            border-radius: 4px ;
            color: $color-neutral-500;
            cursor: pointer ;
            margin-left: 1rem ;

            &:hover {
                background-color: $color-neutral-200;
                color: $color-neutral-700;
            }

            &--open {
                transform: rotate(180deg);
            }
        }
    }

    &__options {
        padding: 1rem ;
        flex: 1 ;
        overflow-y: auto ;
        border-bottom: 2px solid $color-neutral-200 ;
    }
}