.page{
	overflow-x: hidden ; 
	max-width: 100% ;
	--header-height : 14rem ;
	&__wrapper{
		padding: 0 16rem; 
		min-height: calc(100vh - 6.4rem);
		margin-top: var(--header-height) ;

		@include respond(desk){ padding: 0 8rem; }
		@include respond(retinaBig){ padding: 0 12rem; }
		@include respond(tabLand){ padding: 0 4rem; }
		@include respond(tabPort){ padding: 0 4rem; }
		@include respond(phone){ padding: 4.8rem 2rem; --header-height: 8rem ;}

		&--fluid{ padding: 0; }
	}

	&__section{
		padding: 12rem 0;
		width: 100%;

		&:first-child{ padding: 4rem 0 12rem; }
		&:last-child{ padding: 8rem 0; }

		@include respond(phone){
			padding: 4rem 0;
		}
	}
}