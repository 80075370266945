.reader{
	display: flex ;
	height: 100vh ;
	width: 100vw ;
	overflow: hidden ;
	flex-direction: column ;

	&__header {
		position: relative ;
		height: 8rem ;
		flex: 0 0 8rem ;
		display: flex ;
		padding: 0 2rem;
		align-items: center ;
		z-index: 100 ;
		background-color: #fff ;

		&__center {
			flex: 1 ;
			text-align: center ;
			text-transform: capitalize ;

			&__title {
				position: absolute ;
				left: 50% ;
				top: 50% ;
				transform: translate(-50%,-50%);
				font-weight: 500 ;
				font-size: 1.75rem ;

				@include respond(phone){
					display: none ;
				}
			}
		}

		&__left,
		&__right {
			display: flex ;
			& > * + * {
				margin-left: 0.5rem ;
			}
		}

		&__right {
			&__button {
				&--active {
					background-color: $color-secondary ;
					color: #fff ;

					&:hover {
						background-color: $color-secondary ;
						color: #fff ;
					}
				}
			}
		}
	}

	&__container {
		flex: 1 ;
		display: flex ;
		overflow: hidden ;

		&__book {
			position: relative ;
			flex: 1 ;
		}

		&__prev-btn,
		&__next-btn {
			display: flex ;
			align-items: center ;
			justify-content: center ;
			width: 8rem ;

			&__button {
				width : 5rem ;
				height: 5rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;
				border-radius: 50% ;
				cursor: pointer ;
				transition: all 100ms ease-out ;

				&>svg {
					stroke : $color-neutral-500 ;	
				}

				&:hover {
					background-color: $color-neutral-200;
					&>svg {
						stroke : $color-neutral-800 ;
					}
				}
			}
		}
	
		&__bookmark {
			position: absolute ;
			width: 6rem ;
			height: 10rem ;
			right: 4rem ;
			top: 8rem ;
			background-color: $color-secondary ;
			z-index: 99 ;
			clip-path: polygon(
				0% 0%,
				100% 0%,
				100% 100%,
				50% 75%,
				0% 100%	
			);
			transform: translateY(calc(-100% - 8rem));
			transition: all 300ms ease-out ;

			&--show {
				transform: none ;
			}
		}

		&__context-menu-container {
			position: fixed ;
			top : 50% ;
			left: 50% ;
			transform: translate(-50%,-50%) scale(0);
			transition: all 100ms ease-out ;
			opacity: 0 ;
			pointer-events: none ;

			&--show {
				opacity: 1 ;
				pointer-events: all ;
				transform: translate(-50%,-50%) scale(1);
			}
		}
	}

	&__nav{
		height: 8rem ;
		flex: 0 0 8rem ;
		position: relative ;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center; 
		padding: 0 3rem ;
		
		&__progress {
			width: 100% ;
			-webkit-appearance: none;
			appearance: none;
			height: 4px ;
			background-color: $color-neutral-300 ;

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width : 2rem ; 
				height: 2rem ; 
				border-radius: 50% ;
				background-color: $color-secondary ;
			}

			&::-moz-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				width : 2rem ; 
				height: 2rem ; 
				border-radius: 50% ;
				background-color:  $color-secondary ;
			}
		}

		&__progress-value {
			padding: 0 1rem ;
			padding-left: 2rem ;
			text-align: center ;
			color: $color-neutral-500 ;

			&:hover {
				color: $color-neutral-900 ;
			}
		}
	}

	.bookmark__form__action {
		display: flex ;
		&> *{
			flex:1;
		}
		&> * + * {
			margin-left: 1rem ;
		}
	}
}

[data-theme="dark"] .reader {
	background-color: #000 ;
	color: $color-white;

	&__header {
		background-color: #000 ;
	}

	&__container{
		&__prev-btn,
		&__next-btn {
			&__button {
				&:hover {
					color: #fff ;
					background-color: $color-neutral-800;
					&>svg {
						stroke: #fff ;
					}
				}
			}
		}
	}
	
	.button {
		color: $color-neutral-500;
	
		&--icon {
			&:hover {
				background-color: $color-neutral-700 ;
				color: $color-neutral-100;
			}
		}
	}

	&__header {
		background-color: #000 ;
		color: #fff ;

		&__right {
			&__button {
				&--active {
					background-color: $color-secondary ;
					color: #fff !important ;
					&:hover {
						background-color: $color-secondary ;
						color: #fff ;
					}
				}
			}
		}
	}
	
	&__nav {
		&__progress-value{
			color: $color-neutral-100 ;
		}
	}
}

