.button {
	position: relative ;
	border: none ;
	background-color: transparent ;
	cursor: pointer ;
	padding: 1.2rem 1.5rem ;
	color: $color-neutral-500 ;
	border-radius: 0.8rem ;
	transition: all 0.2s ease-out ;
	display: inline-flex ;
	align-items: center ;
	justify-content: center ;
	margin: 1rem 0;

	&:hover {
		background-color: $color-neutral-200 ;
		color: $color-neutral-700 ;
	}

	&> svg {
		width : 3rem ;
		height: 3rem ;
		margin-right: 1rem ;
		stroke: currentColor ;
		pointer-events: none ;
	}

	&>* + svg {
		margin-right: 0 ;
		margin-left: 1rem ;
	}

	&--sm { padding: 1.2rem 1.5rem ; border-radius: 0.8rem ; }
	&--md { padding: 1.2rem 1.5rem ; border-radius: 0.8rem ; }
	&--lg { padding: 1.2rem 1.6rem ; border-radius: 0.8rem ; }
	&--xl { padding: 1.2rem 1.5rem ; border-radius: 0.8rem ; }

	&__icon {
		margin-right: 1.00rem ;
		width: 2.4rem;
		height: 2.4rem;
		object-fit: contain;

		&>svg {margin: 0 ;}

		&--sm { margin: 0.75rem ;}
		&--md { margin: 1.00rem ;}
		&--lg { margin: 1.50rem ;}
		&--xl { margin: 1.75rem ;}
	}

	&--primary { 
		background: linear-gradient(to right,$color-primary,$color-primary-dark) ; 
		background-color: $color-primary ;
		color: $color-white ;

		&:hover {
			background: linear-gradient(to right,$color-primary,$color-primary-dark) ; 
			background-color: $color-primary ;
			color: $color-white ;
		}
	}

	&--secondary { 
		background: linear-gradient(to right,$color-secondary,$color-secondary-dark) ; 
		background-color: $color-secondary ;
		color: $color-white ;
		&:hover {
			background:linear-gradient(to right,$color-secondary,$color-secondary-dark);
			background-color: $color-secondary ;
			color: $color-white ;
		}
	}

	&--white { 
		mix-blend-mode: screen;
		background-color: transparent ; 
		color: $color-black ;
		&:hover {background-color: transparent ; color: $color-black ;box-shadow: inset 0 0 0 0.3rem #0004;}
		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: white;
			border-radius: inherit ;
			mix-blend-mode: color-burn;
		}
	}
	
	&--outline-primary { 
		box-shadow: inset 0 0 0 0.3rem $color-primary; 
		background-color: transparent ;
		color: $color-primary ;

		&:hover {
			background-color: $color-primary ;
			color: $color-white ;
		}
	}

	&--outline-secondary { 
		box-shadow: inset 0 0 0 0.3rem $color-secondary; 
		background-color: transparent ;
		color: $color-secondary ;
		&:hover {
			background-color: $color-secondary ;
			color: $color-white ;
		}
	}

	&--outline-white { 
		border: 0.16rem solid $color-neutral-400;

		&:hover {
			background-color: $color-white ;
			color: $color-black ;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: white;
				border-radius: inherit ;
				mix-blend-mode: color-burn;
			}
		}
	}

	&:disabled {
		background-color: $color-neutral-200 !important;
		background: none ;
		color: $color-neutral-400 !important;
		cursor: default ;
	}
}