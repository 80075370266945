.index{
	height: 100vh;
	width: 100vw;
	
	&__hero{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		height: 100%;
		width: 100%;
	}

	&__bg{
		z-index: -100;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		overflow: hidden;
		object-fit: fill;
	}

	&__content{
		background-color: $color-white;
		border-radius: 1.6rem;
		padding: 2.4rem;
		width: 48rem;
		height: 64rem;
		margin: 2vw 10vw;
		box-shadow: 0.6rem 1.2rem 6rem rgba($color-black, 0.2);

		&__banner{
			height: 4.8rem;
			object-fit: contain;
		}

		&__card{
			margin-top: 2.4rem;

			&>p{margin-bottom: 0;}

			&__divider{
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				margin: 1.6rem 0;

				&>div{
					height: 0.16rem;
					border-radius: 0.8rem;
					background-color: $color-neutral-400;
					width: 100%;
				}

				&>p{
					color: $color-neutral-400;
				}
			}
		}

	}
}