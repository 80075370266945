.side-navbar {
    --header-height:8rem;
    z-index: 199;
    pointer-events: none ;
    transition: all 0.15s ease-out;
    height: calc(100% - var(--header-height));
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: var(--header-height) ;
    right: 0 ;
    width: 100%;
    max-width: 42rem ;
    padding: 0 1rem ; 
    background-color: $color-white ;
    box-sizing: border-box ;
    transform: translateX(100%);
    box-shadow: 0 0 1rem rgba($color-black,0.2);
    overflow: hidden ;

    @include respond(tabPort) {
        display: flex ;
    }

    @include respond(phone) {
        max-width: unset ; 
    }

    &--open{
        pointer-events: all ;
        transform: translateX(0);

    }

    &__container{
        display: flex ;
        flex-direction: column ;
        width: 100%;
        height: 100%;
        padding-top: 1rem ;

        &__header {
            display: flex ;
            align-items: center ;
            justify-content: space-between ;
            margin-bottom: 1rem ;

            &__title {
                color: $color-neutral-700 ;
                margin-left: 2.4rem ;
            }

            &__backbtn {
                width : 4rem ;
                height: 4rem ;
                flex: 0 0 4rem ;
                display: flex ;
                align-items: center ;
                justify-content: center ;
                color: $color-neutral-500;
                border-radius: 4px ;
                cursor: pointer ;

                &>svg {
                    width: 75% ;
                }

                &:hover {
                    background-color: $color-neutral-200 ;
                    color: $color-neutral-900;
                }
            }

            @include respond(tabPort) {
                display: none ;
            }
        }

        &__submenu {
            position: absolute ;
            top: 0 ;
            left: 0 ;
            width: 100% ;
            height: 100% ;
            background-color: $color-white;
            transform: translateX(100%);
            transition: all 200ms ease-out ;
            padding: 1rem ;

            &--open {
                transform: translateX(0);
            }

            &__header {
                height: 4rem ;
                color: $color-black ;
                display: flex ;
                align-items: center ;
                
                
                &__backbtn {
                    width : 4rem ;
                    height: 4rem ;
                    flex: 0 0 4rem ;
                    display: flex ;
                    align-items: center ;
                    justify-content: center ;
                    color: $color-neutral-500;
                    border-radius: 4px ;
                    cursor: pointer ;
                    margin-right: 1rem ;

                    &:hover {
                        background-color: $color-neutral-200 ;
                        color: $color-neutral-900;
                    }
                }

                &__title {
                    color: $color-neutral-500 ;
                }
            }
        }

        &__spacer {
            flex : 1 ;
        }

        &__item{
            position: relative ;
            display: flex ;
            align-items: center ;
            padding: 1em ;
            color: $color-neutral-500 ;
            flex : 0 0 ;
            border-radius: 4px ;

            &:hover {
                color : $color-neutral-900 ;
                background-color: $color-neutral-200 ;
            }

            &::before {
                position: absolute ;
                content: "" ;
                right: 2rem ;
                top: 50% ;
                width : 1rem ;
                height: 1rem ;
                border-top: 0.3rem solid currentColor ;
                border-right: 0.3rem solid currentColor ;
                transform: translateY(-50%) rotate(45deg);
            }

            &> * + * {
                margin-left: 2.4rem ;
            }

            &--icon{
                display: flex ;
                align-items: center ;
            }

            &>img{
                border-radius: 100;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 4rem;
                width: 4rem;
                background-color: $color-secondary;
                object-fit: contain;
            }

            &>svg{
                width:3rem;
                height: 3rem;
            }

            @include respond(phone){
                font-size: 1.6rem;
            }

        }

        &__socials {
            display: flex ;
            justify-content: space-around ;
            border-top: 2px solid $color-neutral-200 ;
            margin-top: 1.2rem ;
            padding: 1.5rem 0;

            &__item {
                text-decoration: none ;
                color : $color-neutral-500 ;
                cursor: pointer ;
                padding: 1rem ;
                display: flex ;
                align-items: center ;
                justify-content: center ;
                border-radius: 4px ;
                &>svg {
                    width : 3rem ;
                    height: 3rem ;
                }

                &:hover {
                    color : $color-neutral-900 ;
                    background-color: $color-neutral-200;
                }
            }
        }

        @include respond(phone){
            font-size: 1.6rem;
        }
    
    }
}