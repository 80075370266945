.snackbar{
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, -2.4rem);
	max-width: 80rem;
	margin: 0 4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: $color-black;
	border-radius: 0.8rem;
	box-shadow: 0 0.2rem 1.2rem rgba($color-black, 0.24);
	padding: 2.4rem;
	z-index: 3000;
	opacity: 1;
	transition: all 0.4s ease-in-out;

	&>p{
		margin-bottom: 0;
	}

	@include respond(phone){
		width: 90% !important;
		margin: 0 auto !important;
	}

	&--hidden{
		opacity: 0;
		z-index: -100;
	}

	&__icon{
		object-fit: contain;
		width: 2rem;
		height: 2rem;
		margin-left: 4rem;
		cursor: pointer;
	}
}