.file-input {
	border-radius: 0.8rem;
	border: 0.2rem solid rgba($color-black, 0.08);
	padding: 1rem ;
	display: flex ;
	background-color: $color-white ;


	&> .button {
		width: min-content ;
		padding: 1rem 2rem ;
		border-radius: 4px ;
		min-width: 0 ;
	}

	&__hidden-input {
		display: none ;
	}

	&__file-name {
		flex: 1 ;
		display: flex ;
		align-items: center;
		margin-left: 1.5rem ;
	}
}