.panel {
	background-color: $color-white ;
	width: calc(100vw - 4rem);
	max-width: 48rem ;
	height: calc(100vh - 16rem);
	box-shadow: 0 0 1rem rgba($color-black,0.2),
	0 0 0.25rem rgba($color-black,0.3);
	border-radius: 1rem ;
	overflow: hidden ;

	&__title {
		height: 6rem ;
		display: flex ;
		align-items: center ;
		justify-content: center ;
		font-weight: 500 ;
		color: $color-secondary ;
		border-bottom: 1px solid $color-neutral-200 ;
	}

	&__container {
		color: $color-neutral-500 ;
		height: calc(100% - 6rem) ;
		overflow-y: auto ;
		padding: 1rem ;

		&__empty {
			text-align: center ;
		}

		&__item {
			height: 6rem ;
			display: flex ;
			align-items: center ;
			border-radius: 4px ;
			padding-left: 2rem ;
			color: $color-neutral-500 ;
			cursor: pointer ;
			transition: all 150ms ease-out ;
			overflow: hidden ;

			&__color {
				width : 1.5rem ;
				height: 1.5rem ;
				border-radius: 50% ;
				opacity: 0.5 ;
				margin-right: 2rem ;
			}

			&__name {
				flex: 1 ;
				overflow: hidden ;
				text-overflow: ellipsis ;
				white-space: nowrap;
			}

			&__delete {
				width: 6rem ;
				height: 6rem ;
				flex: 0 0 6rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;
				&:hover {
					color: $color-red ;
				}
			}

			&:hover {
				background-color: $color-neutral-200 ;
				color: $color-neutral-700 ;
			}
		} 
	}
}

[data-theme="dark"] .panel {
	background-color: $color-neutral-900 ;

	&__title {
		color: $color-secondary ;
		border-bottom: 1px solid $color-neutral-700 ;
	}

	&__container {
		&__item {
			color: $color-neutral-200 ;

			&:hover {
				background-color: $color-neutral-800 ;
				color: $color-neutral-100 ;
			}
		} 
	}
}