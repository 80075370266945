.customizer {
	background-color: $color-white ;
	width: 32rem ;
	box-shadow: 0 0 1rem rgba($color-black,0.2),
	0 0 0.25rem rgba($color-black,0.3);
	border-radius: 1rem ;
	overflow: hidden ;

	&__fontsize {
		height: 6rem ;
		display: flex ;
		align-items: center ;
		border-bottom: 1px solid $color-neutral-200 ;
		
		&__decrease-btn ,
		&__increase-btn{
			flex: 1 ;
			height: 100% ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			color: $color-neutral-500 ;
			cursor: pointer ;

			&:hover {
				color: $color-neutral-900 ;
				background-color: $color-neutral-200 ;
			}
		}

		&__value {
			flex: 1 ;
			display: flex ;
			height: 100% ;
			align-items: center ;
			justify-content: center ;
			font-size: 2rem ;
			color: $color-neutral-500 ;
			border-left: 1px solid $color-neutral-200 ;
			border-right: 1px solid $color-neutral-200 ;
		}
	}

	&__theme {
		height: 6rem ;
		display: flex ;
		align-items: center ;
		border-bottom: 1px solid $color-neutral-200 ;

		&__chip {
			position: relative ;
			flex: 1 ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			height: 100% ;
			cursor: pointer ;

			&--light {
				background-color: #fff ;
				color: #000 ;

				&:hover {
					background-color: $color-neutral-200 ;
					color: $color-neutral-900 ;
				}
			}

			&--dark {
				background-color: #000 ;
				color: #fff ;

				&:hover {
					background-color: #333 ;
				}
			}

			&::before {
				content: "" ;
				position: absolute ;
				left: 50% ;
				bottom: 20% ;
				width: 4rem ;
				height: 0.25rem ;
				border-radius: 4px ;
				background-color: currentColor ;
				transform-origin: 50% 50%;
				transform: translate(-50%) scaleX(0);
				transition: all 150ms ease-out ;
			}

			&--active::before {
				transform: translate(-50%) scaleX(1);
			}
		}
	}

	&__fontfamily {
		&__font {
			height: 6rem ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			border-bottom: 1px solid $color-neutral-200 ;
			cursor: pointer ;
			color: $color-neutral-500 ;

			&:hover {
				background-color: $color-neutral-200 ;
				color: $color-neutral-900 ;
			}

			&--arial {
				font-family: Arial, sans-serif ;
			}

			&--times {
				font-family: 'Times New Roman', Times, serif ;
			}

			&--cursive {
				font-family: "'Brush Script MT', cursive" ;
			}
		}
	}
}

[data-theme="dark"] .customizer {
	background-color: $color-neutral-900 ;

	&__fontsize {
		border-bottom: 1px solid $color-neutral-700 ;
		&__decrease-btn ,
		&__increase-btn{
			color: $color-neutral-200 ;
			&:hover {
				color: $color-neutral-100 ;
				background-color: $color-neutral-700 ;
			}
		}
		&__value {
			color: $color-neutral-200 ;
			border-left : 1px solid $color-neutral-700 ;
			border-right: 1px solid $color-neutral-700 ;
		}
	}

	&__theme {
		border-bottom: 1px solid $color-neutral-700 ;

		&__chip {
			&--light {
				background-color: #fff ;
				color: #000 ;
				&:hover {
					background-color: $color-neutral-200 ;
					color: $color-neutral-900 ;
				}
			}

			&--dark {
				background-color: #000 ;
				color: #fff ;
				&:hover {
					background-color: #333 ;
				}
			}
		}
	}

	&__fontfamily {
		&__font {
			border-bottom: 1px solid $color-neutral-700 ;
			color: $color-neutral-200 ;
			font-size: 2rem ;
			&:hover {
				background-color: $color-neutral-700 ;
				color: $color-neutral-100 ;
			}

		}

		&__font:last-child {
			border-bottom: none ;
		}
	}
}