.explore{
	--header-height:8rem;
	position: relative ;
	overflow: hidden ;
	padding: 0 ;
	margin: 0;
	height: 100vh ;
	padding-top: var(--header-height) ;
	
	&__data{
		display: flex;
		width: 100%;
		height: 100% ;

		&__filter-panel-container {
			--size:40rem;
			flex: 0 0 var(--size);
			max-width: var(--size) ;
			width: var(--size) ;
			border-right: 2px solid $color-neutral-200 ;
			height: 100% ;
			overflow-y: auto ;
			transition: margin 100ms ease-out ;

			@include respond(tabLand){
				&[data-filter-open="false"] {
					margin-left: calc(var(--size) * -1) ;
				}
			}
		}

		&__books {
			flex: 1 ;
			height: 100% ;
			position: relative ; 
			display: flex ;
			flex-direction: column ;
			
			@include respond(phone) {
				min-width: 100vw;
			}

			&__header {
				display: flex ;
				align-items: center ;
				justify-content: space-between ;
				flex: 0 0 7rem ;
				padding: 0 1.5rem ;
				border-bottom: 2px solid $color-neutral-200 ;

				&__filter {
					display: flex ;
					align-items: center ;

					.button {
						display: none !important;
						@include respond(tabLand){display: flex !important;}
					}

					>*+* {
						margin-left: 1rem ;
					}
				}

				&__layout {
					display: flex ;

					&__button {
						@include respond(phone) {
							&>span {display: none ;}
							&>svg {margin: 0 ;}
						}
					}
				}
			}

			&__wrapper {
				flex: 1 ;
				overflow-y: auto ;
				padding: 1rem ;
				
				&[data-layout="GRID"] {
					display: flex ;
					flex-wrap: wrap ;
					gap: 2rem ;
					justify-content: center ;
				}

				&[data-layout="LIST"] {
					display: block ;
				}

				&__empty {
					// position: absolute ;
					// top: 50% ;
					// left: 50% ;
					// transform: translate(-50%,-50%);
					display: flex ;
					align-items: center ;
					justify-content: center ;
					flex-direction: column ;
					color: $color-neutral-600 ;
	
					&>img {
						max-height: 24rem ;
					}
				}

				&__pagination {
					padding: 0 4rem ;
					width: 100% ;
					display: flex ;
					align-items: center ;
					justify-content: center ;

					@include respond(phone) {
						padding: 0 1.75rem ;
					}
				}
			}

		}
	}
}
