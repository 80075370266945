.annotation-context-menu {
	background-color: #fff ;
	padding: 1rem ;
	border-radius: 8px ;
	box-shadow: 0 0 1rem #0003,0 0 4px #0005;
	display: flex ;

	&__container {
		display: flex ;

		&__item {
			--color:black;
			width: 4rem ;
			height: 4rem ;
			position: relative ;
			display: flex ;
			align-items: center ;
			justify-content: center;
			border-radius: 4px ;
			cursor: pointer ;

			&::before {
				content: "" ;
				position: absolute ;
				top: 50% ;
				left: 50%;
				width: 2rem ;
				height: 2rem ;
				border-radius: 50% ;
				transform: translate(-50%,-50%);
				background-color: var(--color);
			}

			&:hover {
				background-color: $color-neutral-200 ;
			}

		}
	}

	&__close {
		width: 4rem ;
		height: 4rem ;
		display: flex ;
		align-items: center ;
		justify-content: center;
	}
}

[data-theme="dark"] .annotation-context-menu {
	background-color: $color-neutral-900 ;

	&__container {
		&__item {
			&:hover {
				background-color: $color-neutral-800 ;
			}
		}
	}
}