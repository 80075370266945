.progressbar {
    position: relative ;
    width: 100% ;
    height: 1rem ;
    background-color: $color-neutral-200 ;
    border-radius: 100rem ;
    overflow: hidden ;

    &__value {
        --progress:0;
        width : calc(var(--progress) * 100%) ;
        height: 100% ;
        border-radius: 100rem ;
        background: linear-gradient(to right,$color-primary,$color-primary-dark);
        transition: all 0.4ms cubic-bezier(0.68, -0.55, 0.265, 1.55) ;
    }

}