.stars {
    display: flex ;

    &__star {
        width: 2.8rem ;
        height: 2.8rem ;
        position: relative ;

        &__icon {
            height: 100%;
			width: 100%;
			object-fit: contain;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;

			&--half{
				&:first-of-type{ left: 0 }
				&:last-of-type{ right: 0 }
			}
        }
    }

    &--small {
        > .stars__star {
            width : 2.4rem ;
            height: 2.4rem ;
        }
    }
}