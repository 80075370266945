@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.typo{
	cursor: inherit;
	transition: all 0.2s ease-in-out;
	font-family: 'Poppins', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;

	&__logo{
		font-family: 'Josefin Sans', sans-serif;
		font-size: 2.5rem;
		font-weight: 700;
		color: $color-neutral-600 ;

		&--white {
			color: $color-white ;
		}
	}

	&__display{
		font-size: 8rem;
		font-weight: 800;
		text-transform: capitalize;

		@include respond(phone){font-size: 4.8rem;}
	}

	&__head{
		font-size: 9.3rem;
		font-weight: 300;

		&--2{
			font-size: 5.8rem;
			font-weight: 300;
		}
		&--3{
			font-size: 4.6rem;
			font-weight: 400;
		}
		&--4{
			font-size: 3.3rem;
			font-weight: 400;
		}
		&--5{
			font-size: 2.3rem;
			font-weight: 400;
		}
		&--6{
			font-size: 1.9rem;
			font-weight: 500;
		}
	}

	&__body{
		font-weight: 400;
		&--2 { font-size: 1.4rem;}
	}

	&__act{
		cursor: pointer;
		font-weight: 500;
		font-size: 1.4rem;
		text-transform: uppercase;

		&--2{
			font-weight: 400;
			font-size: 1.6rem;
		}
	}
	
	&__subtitle{
		font-weight: 300;
		font-size: 1.6rem;
		&--2{ font-size: 1.4rem;}
	}

	&__cap{
		font-weight: 400;
		font-size: 1.2rem;
	}

	&__over{
		font-weight: 400;
		font-size: 1.2rem;
		text-transform: uppercase;
	}

	&__align{
		&--left   {text-align: left  ;}
		&--center {text-align: center;}
		&--right  {text-align: right ;}
	}

	&__transform{
		&--capital {text-transform: capitalize;}
		&--upper   {text-transform: uppercase ;}
		&--lower   {text-transform: lowercase ;}
	}

	&__color{
		&--primary { color: $color-primary !important;}
		&--danger  { color: $color-red     !important;}
		&--success { color: $color-green   !important;}
		&--warning { color: $color-yellow  !important;}
		&--info    { color: $color-blue    !important;}
		&--white   { color: $color-white   !important;}
		&--black   { color: $color-black   !important;}
		&--n100	   { color: $color-neutral-100 !important;}
		&--n200	   { color: $color-neutral-200 !important;}
		&--n300	   { color: $color-neutral-300 !important;}
		&--n400	   { color: $color-neutral-400 !important;}
		&--n500	   { color: $color-neutral-500 !important;}
		&--n600	   { color: $color-neutral-600 !important;}
		&--n700	   { color: $color-neutral-700 !important;}
		&--n800	   { color: $color-neutral-800 !important;}
		&--n900	   { color: $color-neutral-900 !important;}
	}
	
	&--nowrap   {white-space: nowrap;}
	&--noselect {user-select: none  ;}
}