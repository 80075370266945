.header {
	position: fixed ;
	top : 0 ;
	left: 0 ;
	width: 100% ;
	background-color: #fff ;
	z-index: 900 ;

	&__content {
		height: 8rem ;
		display: flex ;
		align-items: center ;
		padding: 0rem 8rem;
		@include respond(desk){ padding: 0rem 4rem; }
		@include respond(retinaBig){ padding: 0rem 8rem; }
		@include respond(tabLand){ padding: 0rem 4rem; }
		@include respond(tabPort){ padding: 0rem 4rem; }
		@include respond(phone){ padding: 0 1.2rem; }

		&__logo {
			display: flex ;
			align-items: center ;

	
			&__image {
				width : 5rem ;
				height: 5rem ;
			}
	
			&__name {
				margin-left: 1.5rem ;
			}
		}
	
		&__search {
			position: relative ;
			width: 100% ;
			max-width: 32rem ;
			margin-left: 20rem ;
			color: $color-neutral-500 ;

			@include respond(tabLand){ display: none ; }
	
			&:hover , &:focus-within  {
				color: $color-neutral-700 ;
			}
	
			&__input {
				appearance: none ;
				--webkit-appearance :none;
				width: 100% ;
				height: 5rem ;
				border-radius: 0.25rem ;
				background-color: $color-neutral-200 ;
				padding: 0 1rem 0 5rem ;
				border: none ;
			}
	
			&__icon {
				position: absolute ;
				top : 0 ;
				left: 0 ;
				width: 5rem ;
				height: 5rem ;
				display: flex ;
				align-items: center ;
				justify-content: center;
			}

			&__result{
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: center;

				&:not(:last-child){
					margin-bottom: 1.6rem;
				}

				&--none{
					&>ul{margin-left: 2rem;}
				}

				&__cover{
					height: 20rem;
					width: 12.5rem;
					object-fit: cover;
				}

				&__info{
					margin-left: 1.2rem;

					&__name{
						text-transform: capitalize;
						word-break: break-word;
						line-height: 1.25;
						margin-top: 0.8rem;
					}

					&__author{
						text-transform: uppercase;
						color: #586274;
					}
				}
			}
		}
	
		&__navbar {
			display: flex ;
			margin-left: auto ;
			align-items: center ;

			@include respond(tabPort){ display: none ; }

			& > * + * {
				margin-left: 0.75rem ;
			}
	
			&__link {
				font-weight: 500 ;
				color: $color-neutral-600 ;
				padding: 1.5rem 2rem ;
				border-radius: 8px ;
				cursor: pointer ;
				font-size: 2rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;

				&>svg {
					width: 3rem ;
					height: 3rem ;
				}
	
				&:hover {
					color: $color-neutral-700 ;
					background-color: $color-neutral-200;
				}

				&>* + * {
					margin-left: 1.5rem ;
				}

				&__subitem {
					position: relative ;
					display: flex ;
					align-items: center ;
					padding: 1em ;
					color: $color-neutral-600 ;
					flex : 0 0 ;
					border-radius: 4px ;

					&:hover {
						color : $color-neutral-700 ;
						background-color: $color-neutral-200 ;
					}

					&::before {
						position: absolute ;
						content: "" ;
						right: 2rem ;
						top: 50% ;
						width : 1rem ;
						height: 1rem ;
						border-top: 0.3rem solid currentColor ;
						border-right: 0.3rem solid currentColor ;
						transform: translateY(-50%) rotate(45deg);
					}

					&> * + * {
						margin-left: 2.4rem ;
					}

					&--icon{
						display: flex ;
						align-items: center ;
					}

					&>img{
						border-radius: 100;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 4rem;
						width: 4rem;
						background-color: $color-secondary;
						object-fit: contain;
					}

					&>svg{
						width:3rem;
						height: 3rem;
					}

					@include respond(phone){
						font-size: 1.6rem;
					}
				}
			}
		}

		&__menu {
			position: relative;
			cursor: pointer;
			margin-left: auto ;
			display: none ;
			
			&:hover {
				background-color: $color-neutral-200 ;
			}

			@include respond(tabPort){
				display: flex; 
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width : 5rem ;
				height: 5rem ;
				border-radius: 8px ;
			}

			&>div{
				height: 2px ;
				width: 3rem ;
				border-radius: 10rem;
				background-color: $color-neutral-500 ;
				transition: all 0.2s ease-out ;
				position: absolute;

				&:nth-child(1){
					top : 50%;
					left: 50%;
					transform: translate(-50%, calc(-50% - 1rem));
				}
				&:nth-child(2){
					top : 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				&:nth-child(3){
					top : 50% ;
					left: 50%;
					transform: translate(-50%, calc(-50% + 1rem));
				}
				&:not(:last-child){margin-bottom: 0.8rem;}
			}

			&--open{

				&>div{
					border-radius: 10rem;

					&:nth-child(1){ transform: translate(-50%, -50%) rotateZ(45deg); }
					&:nth-child(2){ opacity: 0; }
					&:nth-child(3){ transform: translate(-50%, -50%) rotateZ(-45deg); }
				}
			}
			
		}
	}

	&__ribbion {
		width: 100% ;
		display: flex ;
		align-items: center ;
		height: 6rem ;
		color: $color-white ;
		background: linear-gradient(to right,$color-primary,$color-primary-dark);
		box-shadow: $shadow-lg,$shadow-sm ;

		padding: 0rem 8rem;
		@include respond(desk){ padding: 0rem 4rem; }
		@include respond(retinaBig){ padding: 0rem 8rem; }
		@include respond(tabLand){ padding: 0rem 4rem; }
		@include respond(tabPort){ padding: 0rem 4rem; }
		@include respond(phone){ display: none ; }

		&>*+* {
			margin-left: 3rem ;
		}

		&__item {
			font-family: 500 ;
			font-size: 2rem ;
			white-space: nowrap ;
			cursor: pointer ;
			padding: 1rem 1.75rem ;
			border-radius: 8px ;

			&:hover {
				background-color: rgba($color-white,0.1);
			}

			&--label {
				background-color: transparent ;
				&:hover { background-color: transparent }
				cursor: default ;
			}
		}
	}

}

.header[data-nopadding="true"] {
	border-bottom: 2px solid $color-neutral-200 ;
	> .header__content {
		padding-left: 1.5rem ;
		padding-right: 1.5rem ;
	}
}