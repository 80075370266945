.bookmark-panel {
	background-color: $color-white ;
	width: calc(100vw - 4rem);
	max-width: 48rem ;
	height: calc(100vh - 16rem);
	box-shadow: 0 0 1rem rgba($color-black,0.2),
	0 0 0.25rem rgba($color-black,0.3);
	border-radius: 1rem ;
	overflow: hidden ;

	&__title {
		height: 6rem ;
		display: flex ;
		align-items: center ;
		justify-content: center ;
		font-weight: 500 ;
		color: $color-secondary ;
		border-bottom: 1px solid $color-neutral-200 ;
	}

	&__add {
		height: 6rem ;
		display: flex ;
		width: 100% ;
		border-bottom: 1px solid $color-neutral-200 ;

		&__input {
			background-color: transparent ;
			border: 0 ;
			flex: 7 ;
			height: 100% ;
			padding:0 2rem ;
			font-size: 1.5rem ;
			border: 0 ;
			transition: all 100ms ease-out ;
			
			&:focus {
				border: 0 ;
				box-shadow: inset 0 0 0 2px $color-secondary ;
			}

			&::placeholder {
				font-size: 1.5rem ;
				color: $color-neutral-500 ;
			}
		}

		&__button {
			flex: 3 ;
			height: 100% ;
			display: flex ;
			align-items: center ;
			justify-content: center ;
			color: $color-neutral-500 ;
			cursor: pointer ;
			border: 0 ;
			outline: 0 ;
			background-color: transparent ;
			border-left: 1px solid $color-neutral-200 ;

			&:hover {
				color: $color-neutral-900 ;
				background-color: $color-neutral-200 ;
			}
		}
	}

	&__container {
		color: $color-neutral-500 ;
		height: calc(100% - 6rem) ;
		overflow-y: auto ;
		padding: 1rem ;

		&__empty {
			text-align: center ;
		}

		&__item {
			height: 6rem ;
			display: flex ;
			align-items: center ;
			border-radius: 4px ;
			padding-left: 2rem ;
			color: $color-neutral-500 ;
			cursor: pointer ;
			transition: all 150ms ease-out ;
			overflow: hidden ;

			&__name {
				flex: 1 ;
				overflow: hidden ;
				text-overflow: ellipsis ;
				white-space: nowrap;
			}

			&__delete {
				width: 6rem ;
				height: 6rem ;
				flex: 0 0 6rem ;
				display: flex ;
				align-items: center ;
				justify-content: center ;
				&:hover {
					color: $color-red ;
				}
			}

			&:hover {
				background-color: $color-neutral-200 ;
				color: $color-neutral-500 ;
			}
		} 
	}
}

[data-theme="dark"] .bookmark-panel {
	background-color: $color-neutral-900 ;

	&__title {
		color: $color-secondary ;
		border-bottom: 1px solid $color-neutral-700 ;
	}

	&__add {
		border-bottom: 1px solid $color-neutral-700 ;

		&__input {
			color: $color-white ;

			&::placeholder {
				color: $color-neutral-200 ;
			}
		}

		&__button {
			color: $color-neutral-200 ;
			border-left: 1px solid $color-neutral-700 ;

			&:hover {
				color: $color-neutral-100 ;
				background-color: $color-neutral-800 ;
			}
		}
	}

	&__container {
		&__item {
			color: $color-neutral-200 ;

			&:hover {
				background-color: $color-neutral-800 ;
				color: $color-neutral-100 ;
			}
		} 
	}
}